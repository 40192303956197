.Article {
  margin: 0.5em;
  position: relative;
  border: 1px solid #ccc;
  padding-top: 44px;

  .title-wrapper {
    padding: 0 0.2em;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: white;
    color: black;
    height: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #ccc;

    .title {
      font-weight: bold;
      font-size: 12px;
    }
  }

  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
