@import "~bootstrap/scss/bootstrap";

.fulldive-header {
  padding: 0.5em 0 2em 0;

  .logo {
    object-position: 50% 50%;
    height: 173px;
    object-fit: cover;
  }
}

.banner-container {
  overflow: hidden;
  position: relative;
  background-color: black;
  height: 20vw;
  min-height: 200px;
  max-height: 400px;
  font-family: Montserrat;
  font-weight: bold;

  .brand {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 5%;
    bottom: 0;
    right: 0;

    .brand-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      flex-direction: column;
      justify-content: center;
      display: flex;
    }

    .line-clamp {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .brand-text {
      color: white;
      font-size: 48px;
      line-height: 59px;

      @media (max-width: 480px) {
        font-size: 24px;
        line-height: 26px;
      }

      .fulldive-brand {
        color: #EC8E09
      }
    }
  }

  .banner {
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;

    @media (max-width: 480px) {
      right: -10%;
    }

    .iphone {
      height: 100%;
      transform: rotate(5.03deg);
    }
  }
}

.store-buttons-container {
  padding: 1em 1em 2em 1em;

  @media (max-width: 480px) {
    padding: 0 1em;
  }

  .store-button {
    display: inline-block;

    svg {
      width: 80%;
      @media (max-width: 480px) {
        width: 100%;
      }
    }

  }
}
